import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../components/affix/AutoAffix";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import FormContatti from "../../components/form-contatti";

import Square from "../../svg/square-det.svg";
import SquareImgDx from "../../svg/square-img-dx.svg";
import SquareImgSx from "../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Microsoft 365",
    description: "Strumenti, app e idee per connettere e far crescere le aziende.",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Microsoft 365" },
];

const Microsoft = ({ data, location }) => {
  const { intro, sharepoint, fiocchi, engie, sisal, bps, ied, comunemi, enea, biholiday } = data;
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Microsoft 365`}
        description={`Strumenti, app e idee per connettere e far crescere le aziende.`}
      />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Microsoft 365 è la soluzione <strong>cloud</strong> di Microsoft pensata per la
                produttività. La suite di strumenti software comprende servizi cloud intelligenti,
                sicurezza avanzata e app: tutto in modalità SAAS e con possibilità avanzate di
                personalizzazione.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "completezza-servizi-microsoft-esperienza-ariadne",
                        "intranet-8020-nativa-sharepoint",
                        "referenze-tecnologia-microsoft",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#completezza-servizi-microsoft-esperienza-ariadne`}
                          to={`completezza-servizi-microsoft-esperienza-ariadne`}
                          className="anchor-nav__link"
                          title="La completezza dei servizi di Microsoft 365 con l'esperienza di Ariadne"
                        >
                          La completezza dei servizi di Microsoft 365 con l'esperienza di Ariadne
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#intranet-8020-nativa-sharepoint`}
                          to={`intranet-8020-nativa-sharepoint`}
                          className="anchor-nav__link"
                          title="Intranet 80.20 nativa su Sharepoint (Microsoft 365)"
                        >
                          Intranet 80.20 nativa su Sharepoint (Microsoft 365)
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#referenze-tecnologia-microsoft`}
                          to={`referenze-tecnologia-microsoft`}
                          className="anchor-nav__link"
                          title="Referenze su tecnologia Microsoft 365"
                        >
                          Referenze su tecnologia Microsoft 365
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="completezza-servizi-microsoft-esperienza-ariadne" className="anchor">
                  {/* <GatsbyImage
                    image={getImage(completezza)}
                    alt="Image completezza"
                    className="anchor-text__img"fiocchi, engie, sisal, bps, ied, comunemi, enea, biholiday
                  /> */}
                  <h2 className="anchor-text__title">
                    La completezza dei servizi di Microsoft 365 con l'esperienza di Ariadne
                  </h2>
                  <p>
                    L'engineering Ariadne ha acquisito una conoscenza approfondita delle soluzioni
                    di Microsoft 365. Competenze tecniche, architetture software e know-how
                    certificato che continuiamo a coltivare.
                  </p>
                  <h3>Conoscenza della Suite Microsoft 365</h3>
                  <p>
                    Conoscenza approfondita di Microsoft 365 e delle sue{" "}
                    <strong>applicazioni</strong>, tra cui: Office 365, SharePoint, Teams, Exchange
                    Online.
                  </p>
                  <h3>Sviluppo su SharePoint</h3>
                  <p>
                    Capacità di creare soluzioni personalizzate su{" "}
                    <strong>SharePoint Online</strong>, inclusi web part, flussi di lavoro e app di
                    SharePoint.
                  </p>
                  <h3>Automazione dei flussi di lavoro</h3>
                  <p>
                    Conoscenza di <strong>Microsoft Power Automate</strong> (precedentemente noto
                    come Flow) per l'automazione dei flussi di lavoro tra le applicazioni di
                    Microsoft 365.
                  </p>
                  <h3>Sicurezza e conformità</h3>
                  <p>
                    Familiarità con le funzionalità di <strong>sicurezza e conformità</strong> in
                    Microsoft 365 per garantire la protezione dei dati e la conformità normativa.
                  </p>
                  <h3>Integrazione con Azure</h3>
                  <p>
                    Capacità di integrare servizi e risorse di <strong>Azure</strong> con Microsoft
                    365 per migliorare la scalabilità e le funzionalità delle applicazioni.
                  </p>
                  <h3>Sviluppo di Script</h3>
                  <p>
                    Conoscenza di linguaggi di scripting come <strong>PowerShell</strong> per
                    automatizzare attività di amministrazione e gestione in Microsoft 365.
                  </p>
                  <h3>Gestione delle identità</h3>
                  <p>
                    Conoscenza di <strong>Azure Active Directory</strong> per la gestione delle
                    identità utente e l'integrazione con i servizi di Microsoft 365.
                  </p>
                  <h3>Gestione dei dati e delle API</h3>
                  <p>
                    Capacità di lavorare con le <strong>API di Microsoft Graph</strong> per accedere
                    e manipolare dati all'interno di Microsoft 365.
                  </p>
                  <h3>Sviluppo di app mobile</h3>
                  <p>
                    Sviluppo di app per <strong>dispositivi mobili</strong> che si integrano con
                    Microsoft 365, consentendo l'accesso a dati e funzionalità in mobilità.
                  </p>
                  <h3>Conoscenza dei linguaggi di programmazione</h3>
                  <p>
                    Familiarità con <strong>linguaggi di programmazione</strong> come C#,
                    JavaScript, TypeScript e HTML/CSS utilizzati per lo sviluppo su piattaforme
                    Microsoft 365.
                  </p>
                  <h3>Deployment e gestione delle app</h3>
                  <p>
                    Capacità di distribuire e gestire le applicazioni all'interno dell'ambiente
                    Microsoft 365, garantendo un'<strong>esperienza utente</strong> senza intoppi.
                  </p>
                  <h3>Conformità e governance</h3>
                  <p>
                    Conoscenza delle politiche di conformità,{" "}
                    <strong>governance e amministrazione</strong>
                    necessarie per mantenere l'ambiente di Microsoft 365 sicuro e conforme alle
                    normative.
                  </p>
                  <h3>Risoluzione dei problemi e debugging</h3>
                  <p>
                    Abilità nel <strong>rilevare e risolvere problemi</strong> nelle applicazioni e
                    nei servizi Microsoft 365.
                  </p>
                  <p>
                    Queste competenze rappresentano un insieme di abilità fondamentali per chi
                    sviluppa con Microsoft 365. Tuttavia, il campo è in continua evoluzione, quindi
                    rimane imprescindibile per noi l'aggiornamento costante sulle nuove tecnologie e
                    le best practice di questo settore.
                  </p>
                </div>
                <div id="intranet-8020-nativa-sharepoint" className="anchor">
                  <GatsbyImage
                    image={getImage(sharepoint)}
                    alt="Image sharepoint"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Intranet 80.20 nativa su Sharepoint (Microsoft 365)
                  </h2>
                  <p>
                    <a
                      href="https://www.intranet8020.it/"
                      title="Intranet 80.20"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Intranet 80.20
                    </a>{" "}
                    è la soluzione di intranet-in-a-Box pensata dal massimo esperto italiano in
                    materia, Giacomo Mason, ingegnerizzata e realizzata (anche) sul back-office
                    nativo di Microsoft 365, la piattaforma di mercato di riferimento per la modern
                    collaboration sul cloud.
                  </p>
                  <p>
                    Attraverso <strong>Sharepoint</strong>, il software di Microsoft 365 dedicato
                    alle intranet, è possibile disporre di una vasta gamma di funzionalità native,
                    spesso già comprese nelle sottoscrizioni aziendali, armonicamente integrate tra
                    loro nella soluzione Intranet 80.20 che ne esalta le potenzialità.
                  </p>
                  <div className="iframe-responsive">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/pwnW68PN2sU"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen=""
                      title="How to use SharePoint | Microsoft"
                    ></iframe>
                  </div>
                  <p>
                    Sharepoint è lo strumento per <strong>condividere</strong> informazioni e
                    risorse e <strong>collaborare</strong> in modo semplice e in sicurezza
                    all'interno e all'esterno della propria organizzazione.
                  </p>
                </div>
                <div id="referenze-tecnologia-microsoft" className="anchor">
                  <h2 className="anchor-text__title">Referenze su tecnologia Microsoft 365</h2>
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(fiocchi)} alt="logo fiocchi" />
                    </div>
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(engie)} alt="logo engie" />
                    </div>
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(sisal)} alt="logo sisal" />
                    </div>
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(bps)} alt="logo bps" />
                    </div>
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(ied)} alt="logo ied" />
                    </div>
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(comunemi)} alt="logo comunemi" />
                    </div>
                    {/* <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(enea)} alt="logo enea" />
                    </div> */}
                    <div className="anchor-text__wrap-img">
                      <GatsbyImage image={getImage(biholiday)} alt="logo biholiday" />
                    </div>
                  </div>
                  {/* <ul>
                    <li>
                      <strong>Fiocchi</strong>: la intranet Fiocchi è al servizio degli oltre 1000
                      dipendenti nel mondo ed è realizzata con la versione Starter di Intranet 80.20
                      basata su Microsoft 365.
                    </li>
                    <li>
                      <strong>Sisal</strong>: la intranet Sisal è al servizio degli oltre 2.000
                      dipendenti del Gruppo. È realizzata su piattaforma Microsoft 365 ed è dotata
                      della App Mobile dedicata.
                    </li>
                    <li>
                      <strong>IED</strong>: la intranet dell'Istituto Europeo di Design si rivolge
                      ai dipendenti delle sedi di Italia, Spagna e Brasile. È realizzata con
                      Intranet 80.20 su Microsoft 365.
                    </li>
                    <li>
                      <strong>Engie</strong>: intranet aziendale custom, realizzata in base ad una
                      progettazione ed un design personalizzato su MS365
                    </li>
                    <li>
                      <strong>Banca Patrimoni Sella</strong>: portale normative, con progettazione e
                      sviluppo di complessi workflow senza trascurare l’usabilità e la gradevolezza
                      della applicazione
                    </li>
                    <li>
                      <strong>Comune di Milano</strong>: una intranet per quasi 15.000 dipendenti
                      realizzata con Intranet8020 MS365 Release
                    </li>
                  </ul>
                  <GatsbyImage
                    image={getImage(aziende)}
                    alt="Image aziende"
                    className="anchor-text__img"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default Microsoft;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/microsoft.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    completezza: file(relativePath: { eq: "pages/microsoft-completezza.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    sharepoint: file(relativePath: { eq: "pages/microsoft-sharepoint.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    aziende: file(relativePath: { eq: "pages/microsoft-aziende.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    fiocchi: file(relativePath: { eq: "microsoft/logo-fiocchi.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    engie: file(relativePath: { eq: "microsoft/logo-engie.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    sisal: file(relativePath: { eq: "microsoft/logo-sisal.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    bps: file(relativePath: { eq: "microsoft/logo-bps.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    ied: file(relativePath: { eq: "microsoft/logo-ied.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    comunemi: file(relativePath: { eq: "microsoft/logo-comunemi.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    enea: file(relativePath: { eq: "microsoft/logo-enea.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    biholiday: file(relativePath: { eq: "microsoft/logo-biholiday.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
